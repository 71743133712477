import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Placeholder, Segment} from "semantic-ui-react";

const EMBED_URL = "https://embed.twitch.tv/embed/v1.js";


const TwitchEmbed = (props) => {
    const [embed, setEmbed] = useState(null);

    useEffect(() => {
        if (!embed) {
            getEmbed();
        }

    }, []);

    const id = props.channel + "-twitch-embed"

    const getEmbed = async () => {
        const script = document.createElement("script");
        script.setAttribute("src", EMBED_URL);
        script.addEventListener("load", () => {
            const embed = new window.Twitch.Embed(
                id, {...props, targetID: id, layout: "video",}
            );
            setEmbed(embed)
        });
        document.body.appendChild(script);
    }

    return (
        <Segment inverted color='purple' style={{padding: ".2em"}}>
            {!embed &&
            <Placeholder fluid>
                <Placeholder.Image rectangular/>
            </Placeholder>
            }
            <div style={{padding: 0, height: "480px"}} id={id}/>
        </Segment>
    )
}

TwitchEmbed.defaultProps =
    {
        width: "100%",
        height: "100%",
    }

TwitchEmbed.propTypes =
    {
        width: PropTypes.any.isRequired,
        height: PropTypes.any.isRequired,
        channel: PropTypes.string.isRequired
    };

export default TwitchEmbed;
