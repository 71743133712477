import React, {useEffect, useState} from 'react'
import {
    Container,
    Divider,
    Dropdown,
    Grid,
    GridColumn,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Segment,
} from 'semantic-ui-react'
import jwt_decode from "jwt-decode"
import TwitchEmbed from "../../components/twitchEmbed";
import axios from "axios";
import StreamerInfoCard from "../../components/streamerInfoCard";
import StreamerThumbCard from "../../components/streamerThumbCard";

const MainPage = () => {
    const [user, setUser] = useState(null);

    const [streamers, setStreamers] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('current')
        if (!user && token.length > 0) {
            getUser(token);
        }

        if (!streamers && token.length > 0) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            getStreamers("Auberge");
        }

    }, []);
    const getUser = async (token) => {
        const user_ = jwt_decode(token);
        console.log("user:", user_)
        setUser(user_);
    };

    const getStreamers = async (team) => {
        axios.get(
            "https://twitch.astyweb.com/streams/" + team
        ).then(
            (response) => {
                console.log('headers:', response.headers)
                setStreamers(response.data.data)
                // localStorage.setItem('current', response.headers.Authorization)
            }
        ).catch(error => {
            console.error(error);
            setStreamers(null)
            localStorage.removeItem('current')
            window.location.href = "/"
        });
    };

    let username = user ? user.login : 'Loading...'
    username = username.charAt(0).toUpperCase() + username.slice(1)

    console.log("streamers", streamers)

    return (
        <div>
            <Menu fixed='top' inverted pointing>
                <Container>
                    <Menu.Item as='a' header>
                        <Image size='mini' src='/assets/images/Logo_solo.png' style={{marginRight: '1.5em'}}/>
                        L'auberge
                    </Menu.Item>
                    <Menu.Item as='a'>Accueil</Menu.Item>

                    <Dropdown item simple text={username}>
                        <Dropdown.Menu>
                            <Dropdown.Item>Déconnexion</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Container>
            </Menu>

            <Container fluid>
                <Segment inverted style={{margin: '5em 0em 0em', padding: '1em 1.5em'}}>
                    <Grid stackable verticalAlign='middle'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <TwitchEmbed channel={"Astymeus"}/>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <StreamerInfoCard channel={"Astymeus"}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>

            <Container fluid style={{margin: '1em 0em'}}>
                <Segment inverted style={{padding: '8em 0em'}} vertical>
                    <Grid divided padded inverted>
                        <Grid.Row stretched>
                            {streamers && streamers.filter(s => s.is_live).map(s => (
                                <GridColumn key={s.id} width={4}>
                                    <StreamerThumbCard channel={s}/>
                                </GridColumn>
                            ))}
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>

            <Container fluid style={{margin: '1em 0em'}}>
                <Segment inverted style={{padding: '8em 0em'}} vertical>
                    <Grid divided inverted>
                        <Grid.Row stretched>
                            {streamers && streamers.filter(s => !s.is_live).map(s => (
                                <GridColumn key={s.id} width={4}>
                                    <StreamerThumbCard channel={s}/>
                                </GridColumn>
                            ))}
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>


            <Segment inverted vertical style={{margin: '1em 0em 0em 0em', padding: '1em 0em'}}>
                <Container textAlign='center'>
                    <Grid divided inverted stackable>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='Teams'/>
                            <List link inverted>
                                <List.Item as='a'>Auberge</List.Item>
                                <List.Item as='a'>Les Gros</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='Discord'/>
                            <List link inverted>
                                <List.Item as='a'><Icon name="discord"/>Auberge</List.Item>
                                <List.Item as='a'><Icon name="discord"/>Les Gros</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header inverted as='h4' content='Twitch HUB'/>
                            <p>
                                ©Copyright, 2021 - astyweb.com - Twitch HUB
                            </p>
                            <p>
                                Retrouvez ici toutes les chaînes des communautés dans lesquelles je participes.
                            </p>
                        </Grid.Column>
                    </Grid>

                    <Divider inverted section/>
                    <Image centered size='mini' src='/assets/images/Logo_solo.png'/>
                    <List horizontal inverted divided link size='small'>
                        <List.Item as='a' href='#'>
                            Site Map
                        </List.Item>
                        <List.Item as='a' href='#'>
                            Contact Us
                        </List.Item>
                        <List.Item as='a' href='#'>
                            Terms and Conditions
                        </List.Item>
                        <List.Item as='a' href='#'>
                            Privacy Policy
                        </List.Item>
                    </List>
                </Container>
            </Segment>
        </div>
    )
}

export default MainPage
