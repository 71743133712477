import {Card, Icon, Image} from "semantic-ui-react";
import PropTypes from "prop-types";
import React from "react";

const StreamerThumbCard = (props) => {

    let streamer = props.channel;

    return (
        <Card fluid>
            <Image
                src={streamer.thumbnail_url.replace('{width}', 320).replace('{height}', 180)}
                wrapped
            />
            <Card.Content>
                <Card.Header>{streamer.display_name}</Card.Header>
                <Card.Meta>{streamer.game_name}</Card.Meta>
                <Card.Description>
                    {streamer.title}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <a>
                    <Icon name='user'/>
                    {streamer.viewer_count} Spectateurs
                </a>
            </Card.Content>
        </Card>
    )
};

StreamerThumbCard.propTypes =
    {
        channel: PropTypes.object.isRequired
    };

export default StreamerThumbCard
