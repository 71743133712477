import React from 'react'
import {Button, Form, Grid, Icon, Image, Segment} from 'semantic-ui-react'

const LoginForm = () => {

    return (
        <div className="min-vh-100 w-100 flex flex-column items-center justify-center">
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Form size='large' action="https://twitch.astyweb.com/oauth_initiate">
                        <Segment
                            color='violet'
                            style={{backgroundColor: 'rgba(0,0,0,.5)'}}
                        >
                            <Image src='/assets/images/Logo.png'/>
                            <Button
                                icon
                                // fluid
                                color='violet'
                                size='large'
                                labelPosition='left'
                                type="submit"
                            >
                                <Icon name='twitch'/>
                                Login with Twitch
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default LoginForm
