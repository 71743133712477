import {Button, Card, Icon, Image, Placeholder, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import axios from "axios";

const StreamerInfoCard = (props) => {
    const [streamer, setStreamer] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('current')
        if (!streamer && token.length > 0) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            getStreamer(props.channel);
        }

    }, [props.channel]);

    const getStreamer = async (username) => {
        axios.get(
            "https://twitch.astyweb.com/channel/" + username
        ).then(
            (response) => {
                setStreamer(response.data)
                console.log('headers:', response.headers)
                // localStorage.setItem('current', response.headers.Authorization)
            }
        ).catch((error) => {
            console.error(error);
            setStreamer(null)
            localStorage.removeItem('current')
            window.location.href = "/"
        });
    };

    return (
        <Card fluid>
            {streamer ?
                <Card.Content>
                    <Image
                        floated='left'
                        size='mini'
                        src={streamer.profile_image_url}
                    />
                    <Card.Header>{streamer.display_name}</Card.Header>
                    <Card.Meta>{streamer.followers}<Icon name='circle' color='red'/> 546 spectateurs</Card.Meta>
                    <Card.Description>
                        {streamer.description}
                    </Card.Description>
                </Card.Content> :
                <Segment basic>
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Segment>
            }
            <Card.Content extra>
                <div className='ui two buttons'>
                    <Button icon basic disabled={!streamer} color='purple'>
                        <Icon name='heart'/>
                        Suivre
                    </Button>
                    <Button icon basic inverted disabled={!streamer} color='purple'>
                        <Icon name='twitch'/>
                        Voir sur Twitch
                    </Button>
                </div>
            </Card.Content>
        </Card>
    )
};

StreamerInfoCard.propTypes =
    {
        channel: PropTypes.string.isRequired
    };

export default StreamerInfoCard
